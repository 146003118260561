const getAnswer = (response) => {
	const {
		data: [
			{
				symbol: [{ error, data }],
			},
		],
	} = response;
	return error ? 'Không phát hiện mã QR trong ảnh' : data;
};
export default getAnswer;
