<template>
	<form class="mt-8 px-4" @submit.prevent="generateQrCodeImage" method="POST">
		<div>
			<textarea
				required
				class="appearance-none w-full h-32 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
				placeholder="type your text"
				v-model="text"
			/>
		</div>
		<div class="mt-1">
			<button
				type="submit"
				class="w-full py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
			>
				Tạo mã
			</button>
		</div>
	</form>
	<img class="mt-5 mx-auto w-auto" v-if="imageSrc" :src="imageSrc" />
</template>

<script>
	export default {
		name: 'ComponentFormGenerateCode',
		data() {
			return {
				text: '',
				imageSize: '200x200',
				imageSrc: '',
			};
		},
		methods: {
			generateQrCodeImage() {
				this.imageSrc = `https://api.qrserver.com/v1/create-qr-code/?data=${this.text}&size=${this.imageSize}`;
			},
		},
	};
</script>
