<template>
	<button class="mx-auto mt-40 btn" v-if="webcamIsClose && !reading" @click="openCamera">
		Mở camera
	</button>
	<div class="mt-8 flex flex-col items-end">
		<div>
			<video v-show="webcamIsOpen" ref="webcam" autoplay playsinline width="640" height="480" />
			<canvas v-show="reading" ref="canvas" />
			<div v-if="webcamIsOpen || reading" class="flex flex-row pt-8">
				<!-- hien tai webcam-easy chi ho tro switch 1 lan tu back to front camera, nhung lan sau chi la dao chieu camera front -->
				<button v-if="isMobile && !flipped" class="btn" @click="flipCamera">Camera trước</button>
				<button class="btn mx-auto" @click="readCode">
					<spiner v-if="reading" class="-ml-1 mr-5 text-white" />Đọc mã QR
				</button>
			</div>
		</div>
	</div>
	<div v-if="answer" class="mt-8 max-w-md mx-auto answer" ref="answer">
		{{ answer }} -
		<button v-if="canCopy" class="text-blue-600 text-xs" @click="copy">
			Sao chép
		</button>
	</div>
</template>

<script>
	import Webcam from 'webcam-easy';
	import Spiner from './Spiner';
	import {
		clearCanvas,
		generateFormData,
		getAnswer,
		sendRequest,
		isMobile,
		considerOpenURL,
	} from '../assets/helpers/read_code/';

	export default {
		name: 'ComponentFormReadCode',
		components: { Spiner },
		data() {
			return {
				webcam: null,
				webcamIsClose: true,
				answer: null,
				reading: false,
				flipped: false,
			};
		},
		computed: {
			webcamIsOpen() {
				return !this.webcamIsClose;
			},
		},
		created() {
			this.canCopy = !!navigator.clipboard;
			this.isMobile = isMobile(navigator);
		},
		unmounted() {
			this._closeCamera();
		},
		methods: {
			async openCamera() {
				const webcamElement = this.$refs.webcam;
				const canvasElement = this.$refs.canvas;
				this._reset(canvasElement);
				const facingMode = this.isMobile ? 'environment' : 'user';
				this.webcam = new Webcam(webcamElement, facingMode, canvasElement);
				await this.webcam.start();
				this.webcamIsClose = false;
			},
			flipCamera() {
				this.flipped = true;
				this.webcam.flip();
				this.webcam.start();
			},
			async readCode() {
				this.reading = true;
				this.webcam.snap();
				await this._closeCamera();
				const formData = await generateFormData(this.$refs.canvas);
				const response = await sendRequest(formData);
				this.answer = getAnswer(response);
				considerOpenURL(this.answer);
				this.reading = false;
			},
			async copy() {
				await navigator.clipboard.writeText(this.answer);
				alert('đã sao chép!');
			},
			_reset(canvasElement) {
				clearCanvas(canvasElement);
				this.answer = null;
			},
			async _closeCamera() {
				await this.webcam.stop();
				this.webcamIsClose = true;
			},
		},
	};
</script>
