const isMobile = (navigatorObject) => {
	// credit to Timothy Huang for this regex test:
	// https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigatorObject.userAgent)) {
		return true;
	} else {
		return false;
	}
};
export default isMobile;
