import axios from 'axios';
const sendRequest = async (formData) => {
	const apiUrl = 'https://api.qrserver.com/v1/read-qr-code/';
	const response = await axios({
		url: apiUrl,
		method: 'post',
		data: formData,
		header: { 'Content-Type': 'multipart/form-data' },
	});
	return response;
};
export default sendRequest;
