<template>
	<div class="flex justify-center items-baseline space-x-10 pt-5">
		<a
			href="#generate"
			@click="nav = 'generate'"
			:class="{ 'text-gray-900': nav === 'generate' }"
			class="nav-title text-gray-400"
		>
			Tạo mã QR
		</a>
		<a
			href="#read"
			@click="nav = 'read'"
			class="nav-title text-gray-400"
			:class="{ 'text-gray-900': nav === 'read' }"
		>
			Đọc mã QR
		</a>
	</div>
	<div class="flex flex-col items-center">
		<div class="max-w-md w-full h-full">
			<form-generate-code v-if="nav === 'generate'" />
		</div>
	</div>
	<form-read-code v-if="nav === 'read'" />
</template>

<script>
	import FormReadCode from './components/FormReadCode';
	import FormGenerateCode from './components/FormGenerateCode';
	export default {
		name: 'App',
		components: { FormReadCode, FormGenerateCode },
		data() {
			return {
				nav: location.hash ? location.hash.substring(1) : 'generate',
			};
		},
	};
</script>
